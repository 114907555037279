<script lang="ts">
	import { onMount } from 'svelte';
	import { PUBLIC_GENERAL_SERVICE_URL } from '$env/static/public';
	import { generateSignature } from '$lib/utils/Signature';
	import { detectClickOutside } from '$lib/utils/detectClickOutside';
	import cn from 'classnames';

	let brands_search: any[] = [];
	let brands_search_result: any[] = [];
	let selected_brand: any = '';
	let is_loading: boolean = false;
	let is_open: boolean = false;

	onMount(async () => {
		let url = PUBLIC_GENERAL_SERVICE_URL + 'scooters/producers/';
		let signature = await generateSignature();

		let request = await fetch(url, { headers: signature });
		brands_search = await request.json();
		brands_search_result = brands_search;
	});

	const filter = (e: any) => {
		console.log(e.target.value);
		if (e.target.value == '') {
			is_open = true;
			brands_search_result = brands_search;
			return;
		}
		selected_brand = e.target.value;
		brands_search_result = brands_search.filter((brand) =>
			brand.producer.toLowerCase().startsWith(selected_brand.toLowerCase())
		);
		
		is_open = true;
	};

	const onKeyDown = (e: any) => {
		if (e.keyCode == 27) {
			selected_brand = '';
			brands_search_result = [];
		}
	};

	const handleClickOutside = () => {
		is_open = false;
		brands_search_result = [];
	};
</script>

<div
	class={cn('justify-left relative z-10 flex max-w-[50rem]', $$props.class)}
	use:detectClickOutside
	on:click_outside={handleClickOutside}
>
	<div class="fake-select relative w-full">
		<label>
			<input
				on:keydown={onKeyDown}
				on:click|stopPropagation={filter}
				on:input={filter}
				bind:value={selected_brand}
				data-test="search_producer"
				placeholder="Zvolte výrobce:"
				class="form-select px-4.5 border-primary focus:border-focusblue m-0 block w-full
             appearance-none border-4 border-solid bg-white bg-clip-padding bg-no-repeat
             py-3.5 indent-8 text-gray-700 transition ease-in-out focus:bg-white
             focus:text-gray-700 focus:outline-none"
				name="brands"
			/>
		</label>
		<div
			data-test="producers_list"
			class="{is_open || is_loading
				? 'show-list'
				: ''} test focus:border-focusblue absolute w-full
       overflow-y-auto border-4 bg-white bg-clip-padding bg-clip-padding bg-no-repeat text-gray-700
      transition ease-in-out focus:bg-white focus:text-gray-700 focus:outline-none"
		>
			<div
				class:hidden={!is_loading}
				class="w-100 flex cursor-pointer p-2 pl-5 transition hover:bg-gray-100"
			>
				načítám...
			</div>
			{#each brands_search_result as producer, i (producer)}
				<a
					id={producer.producer}
					href="/skutry/{producer.producer}/"
					class:bg-gray-100={selected_brand === i}
					class="w-100 flex cursor-pointer p-2 pl-5 transition hover:bg-gray-100"
					data-test="producer_{producer.producer}"
					title="Přehled modelů výrobce {producer.producer} na které máme nejen náhradní díly, ale také doplňky, technické údaje a další užitečné informace"
				>
					{producer.producer}
				</a>
			{/each}
		</div>
	</div>
</div>

<style>
	.test {
		height: 0;
		visibility: hidden;
	}
	.show-list {
		height: auto !important;
		max-height: 300px;
		visibility: unset;
	}
</style>
